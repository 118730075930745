import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DEFAULT_ROW_HEIGHT } from '../../constants/data-grid';
import { backendFiltersTransformation } from '../../helpers';
import { useAvailableHeight, useIsMobile } from '../../hooks';
import {
  sortModelToString,
  stringOrderByToSortModel,
} from '../tables/utils/selectionModelHandlers';
import DataGridCheckbox from './DataGridCheckbox';
import DataGridLoader from './DataGridLoader';
import DataGridNoRowsOverlay from './DataGridNoRowsOverlay';
import DataGridRenderHeader from './DataGridRenderHeader';

const scrollerClass = makeStyles(() => ({
  virtualScroller: { overflowX: 'hidden', overflow: 'overlay' },
}));

const FingoDataGrid = ({
  rows,
  columns,
  includeHeaders,
  mobileHeaders,
  loadingWithSkeleton,
  rowsPerPageOptions,
  noRowsMessage,
  serverFilters,
  setColumnFilter,
  setOrderBy,
  orderBy,
  rowHeight,
  refetch,
  rowCount,
  page,
  pageSize,
  maxHeight,
  disableSelectionOnClick,
  outsideBoxProps,
  disabledCheckboxTooltip,
  DataGridNoRowsOverlayComponent = DataGridNoRowsOverlay,
  additionalQueriesResults,
  ...restProps
}) => {
  const classes = scrollerClass();
  const gridRowCount = rowCount || rows.length;
  const gridPageSize = pageSize || Math.min(rows.length, 100);
  const [filterModel, _setFilterModel] = useState();
  const [height, refChange] = useAvailableHeight();
  const isMobile = useIsMobile();
  const boxRef = useRef(null);
  const setSortModel = useCallback(
    (model) => {
      const stringOrder = sortModelToString(model);
      setOrderBy(stringOrder);
    },
    [setOrderBy, restProps],
  );
  const sortModel = useMemo(() => stringOrderByToSortModel(orderBy), [orderBy]);
  const setFilterModel = useCallback(
    (value) => {
      _setFilterModel(value);
      setColumnFilter(backendFiltersTransformation(value?.items?.[0]));
    },
    [setColumnFilter],
  );
  const checkboxWidth = useMemo(() => (isMobile ? 18 : 30), [isMobile]);
  const checkboxDef = useMemo(
    () => ({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      minWidth: checkboxWidth,
      width: checkboxWidth,
      type: 'string',
    }),
    [],
  );

  const fingoColumns = useMemo(() => {
    const dataGridColumns = columns.map((column) => ({
      ...column,
      renderHeader: DataGridRenderHeader,
    }));
    return dataGridColumns.sort(
      (a, b) => includeHeaders.indexOf(a.field) - includeHeaders.indexOf(b.field),
    );
  }, [columns, includeHeaders, mobileHeaders, isMobile]);
  const serverProps = useMemo(
    () => ({
      paginationMode: 'server',
      sortingMode: 'server',
      filterMode: 'server',
      pagination: true,
      sortModel,
      filterModel,
      onFilterModelChange: setFilterModel,
      onSortModelChange: setSortModel,
    }),
    [setFilterModel, setSortModel, sortModel, filterModel],
  );

  // Helper for scrolling to the top after page change
  const handlePageChange = useCallback(() => {
    if (boxRef.current) {
      boxRef.current.scrollTo(0, 0);
    }
  }, [boxRef.current]);

  useEffect(() => {
    handlePageChange();
  }, [page, gridPageSize]);

  const boxHeight = useMemo(() => {
    const defaultRowHeight = restProps?.density === 'comfortable' ? 40 : 8;
    const rowsDisplayed = Math.min(
      gridPageSize,
      gridRowCount - page * gridPageSize,
    );
    return gridRowCount === 0
      ? height
      : rowsDisplayed * (rowHeight + defaultRowHeight) + 125;
  }, [gridPageSize, gridRowCount, page, height]);
  const columnVisibilityModel = useMemo(
    () => fingoColumns
      .map((column) => {
        if (includeHeaders.length) {
          return {
            [column.field]: isMobile
              ? mobileHeaders.includes(column.field)
              : includeHeaders.includes(column.field),
          };
        }
        return {
          [column.field]: true,
        };
      })
      .reduce((a, b) => ({ ...a, ...b })),
    [isMobile, fingoColumns],
  );

  // Enhance columns which need additional query results
  const enhancedColumns = useMemo(() => fingoColumns.map((column) => {
    if (!column.useAdditionalQueries) {
      return column;
    }

    return {
      ...column,
      renderCell: (params) => {
        if (column.renderCell) {
          const queryField = column.queryField || column.field;
          const columnResults = additionalQueriesResults?.[queryField];
          const key = `${params.row.id}-${queryField}-${columnResults?.loading}`;
          return (
            <div key={key}>
              {column.renderCell({
                ...params,
                additionalQueriesResults: columnResults ? {
                  [queryField]: {
                    loading: columnResults.loading ?? false,
                    error: columnResults.error,
                    data: columnResults.data,
                  },
                } : null,
              })}
            </div>
          );
        }
        return params.value;
      },
    };
  }), [fingoColumns, additionalQueriesResults]);

  return (
    <Box height="100%" overflow="auto" ref={boxRef} {...outsideBoxProps}>
      <Box
        id="datagrid-box"
        ref={refChange}
        sx={{
          '& .littleHeader': {
            fontSize: 11,
          },
        }}
        height={boxHeight}
        width="100%"
        maxHeight={maxHeight}
      >
        <DataGrid
          columns={[checkboxDef, ...enhancedColumns]}
          rows={loadingWithSkeleton ? [] : rows}
          rowsPerPageOptions={rowsPerPageOptions}
          rowHeight={rowHeight}
          scrollbarSize={0}
          loading={loadingWithSkeleton}
          components={{
            LoadingOverlay: DataGridLoader,
            BaseCheckbox: DataGridCheckbox,
            NoRowsOverlay: DataGridNoRowsOverlayComponent,
          }}
          columnVisibilityModel={columnVisibilityModel}
          componentsProps={{
            baseCheckbox: { disabledCheckboxTooltip }, // Props for your custom checkbox
            noRowsOverlay: { Message: noRowsMessage }, // Props for your custom no rows overlay
          }}
          classes={{ virtualScroller: classes.virtualScroller }}
          rowCount={gridRowCount}
          page={page}
          pageSize={gridPageSize}
          disableSelectionOnClick={disableSelectionOnClick}
          density={isMobile ? 'compact' : 'standard'}
          sx={{
            '& .MuiDataGrid-cell': {
              px: {
                xs: 0,
                md: 1,
              },
            },
            '& .MuiDataGrid-cellCheckbox, & .MuiDataGrid-columnHeaderCheckbox':
              {
                px: '0 !important',
              },
            '& .MuiDataGrid-columnHeader': {
              px: {
                xs: 0,
                md: 1,
              },
            },
          }}
          {...(serverFilters && serverProps)}
          {...restProps}
        />
      </Box>
    </Box>
  );
};

FingoDataGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape),
  rowCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  parentBoxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  includeHeaders: PropTypes.arrayOf(PropTypes.string),
  mobileHeaders: PropTypes.arrayOf(PropTypes.string),
  loadingWithSkeleton: PropTypes.bool,
  noRowsMessage: PropTypes.func,
  serverFilters: PropTypes.bool,
  setColumnFilter: PropTypes.func,
  setOrderBy: PropTypes.func,
  rowHeight: PropTypes.number,
  orderBy: PropTypes.string,
  refetch: PropTypes.func,
  disabledCheckboxTooltip: PropTypes.string,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableSelectionOnClick: PropTypes.bool,
  outsideBoxProps: PropTypes.shape({}),
  DataGridNoRowsOverlayComponent: PropTypes.func,
  additionalQueriesResults: PropTypes.shape({}),
};

FingoDataGrid.defaultProps = {
  loadingWithSkeleton: false,
  noRowsMessage: () => (
    <Typography variant="body1" align="center">
      Sin filas
    </Typography>
  ),
  serverFilters: true,
  rowHeight: DEFAULT_ROW_HEIGHT,
  parentBoxHeight: null,
  mobileHeaders: [],
  includeHeaders: [],
  setColumnFilter: () => {},
  setOrderBy: () => {},
  orderBy: undefined,
  refetch: () => {},
  rowsPerPageOptions: [30, 50, 75, 100],
  maxHeight: null,
  rowCount: null,
  page: 0,
  pageSize: null,
  rows: [],
  disableSelectionOnClick: true,
  disabledCheckboxTooltip: '',
  outsideBoxProps: {},
  DataGridNoRowsOverlayComponent: DataGridNoRowsOverlay,
  additionalQueriesResults: {},
};

export default FingoDataGrid;
